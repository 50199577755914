import { IPersmissions } from "../models/root.interface";
import { SET_PERMISSIONS } from "../actions/auth.actions";

const initialState = {
    isauth: "1"

};

function authReducer(state= initialState, action){
    switch(action.type){
        case SET_PERMISSIONS:

            return {...state, ...action.payload};
        default:
            return {...state};
    };
};

export default authReducer;