import React, { Fragment,Component } from "react";
import Toolbar from "../Navigation/Toolbar/Toolbar";

import classes from './Layout.module.css';
import SideDrawer from '../Navigation/SideDrawer/SideDrawer';
class Layout extends Component {
    state={
        showSideDrawer:false
    }
    SideDrawerClosedHandeler=()=>{
this.setState({showSideDrawer:false});
    }
    SideDrawerToggleHandeler=()=>{
        this.setState((prevstate)=>{
            return {showSideDrawer:!prevstate.showSideDrawer}
        });
            }
    render(){
        return(
        <Fragment >
            <div   >
        <Toolbar drawerToggleClicked={this.SideDrawerToggleHandeler}/>
        <SideDrawer open={this.state.showSideDrawer} closed={this.SideDrawerClosedHandeler}/>
                
            </div>
            <main className={classes.Content} style={{marginTop:"150px"}} >
                {this.props.children}
            </main>
        </Fragment>

        );

    }

}
export default Layout;