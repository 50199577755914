import React, { useState, useEffect, Fragment } from 'react';
import './App.css';
import Layout from './components/Layout/Layout';
import FormsList from './Views/FormsList/FormsList';
import NewForm from './Views/NewForm/NewForm';
import MyForms from './Views/MyForms/MyForms';
import ViewForm from './Views/ViewForm/ViewForm';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import { BASE_URL } from './variables/api';
import Cookies from 'js-cookie';
import axios from 'axios';
import { useDispatch } from 'react-redux'

import Swal from 'sweetalert2';
import { setPermissions } from './store/actions/auth.actions';
import { setUserLoggedIn } from './store/actions/user.actions';
import jwt_decode from "jwt-decode";
import { useSelector } from 'react-redux';
import EditForm from './Views/EditForm/EditForm';
import DetaileFormView from './Views/DetailedFormView/DetailedFormView';
import Publish from './Views/Publish/Publish';
import ViewResponse from './Views/MyForms/ViewResponse/ViewResponse';
import PublishForms from './Views/PublishedForms/PublishedForms';
import Summary from './Views/Summary/Summary';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import SchoolSelectInfoBar from './components/UI/SchoolSelectInfoBar';
import useCheckSuperAdmin from './hooks/useCheckSuperAdmin';
axios.defaults.withCredentials = true;


const App = () => {


    const dispatch = useDispatch();
    const permissions = useSelector((state) => state.permissions);
    const [user, setUser] = useState([]);
    const [status, setStatus] = useState([]);
    const [surveys, setSurveys] = useState([]);
    const [responses, setResponses] = useState([]);
    const [recentResponse, setRecentResponses] = useState([]);
    const [pending, setPending] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isauth, setisauth] = useState("0");

    const schoolLocallyStored = localStorage.getItem("selected_school");
    if (schoolLocallyStored) {
      axios.defaults.headers.common['emaildomain'] = `${JSON.parse(schoolLocallyStored).value}`;
    }

    useEffect(() => {

        const token = Cookies.get("surveytoken");
        let decodedToken = {};
        const pathname = window.location.pathname;

        try {
            if (pathname.search("mypublished") == -1)
                if (token) {
                    setisauth("1");
                    decodedToken = jwt_decode(token || "");
                    console.log(decodedToken);

                    axios.post(`${BASE_URL}/auth/updateroles.php`,
                        { username: decodedToken.data.user.username }).then((res) => {
                            axios.get(`${BASE_URL}/auth/getuserandemployeeinupdateroles.php`).then((data) => {

                                setUser((prevstate) => ({
                                    ...prevstate,
                                    id: data.data.data.user.id,
                                    username: data.data.data.user.displayname,
                                    role_id: data.data.data.user.role_id
                                }));
                                setStatus(data.data.data.status);
                                setSurveys(data.data.data.surveys);
                                setResponses(data.data.data.responses);
                                setRecentResponses(data.data.data.recentresponse);
                            }
                            );

                            if (res.data.data !== null) {
                                decodedToken = jwt_decode(res.data.data || "");
                                if (decodedToken && decodedToken.data && decodedToken.data.user)
                                    dispatch(setPermissions({ ...decodedToken.data.user, isauth: "1" }));
                                localStorage.setItem('emaildomain', decodedToken.data.user.username.split("@")[1]);
                                dispatch(setUserLoggedIn(decodedToken.data.user));
                            }
                            else {
                                RedirectToLogin();
                            }

                        });
                    return;
                } else {
                    axios.post(`${BASE_URL}/auth/login.php`)
                        .then((response) => {
                            console.log("here from login ")
                            //logging.info(response.data.user, 'SAML');
                            setUser((prevstate) => ({
                                ...prevstate,
                                id: response.data.data.user.id,
                                username: response.data.data.user.displayname,
                                role_id: response.data.data.user.role_id
                            }));

                            if (response.data.data.token) {
                                setisauth("1");
                                const token = response.data.data.token;
                                Cookies.set("surveytoken", token, { sameSite: 'none', secure: true });
                                decodedToken = jwt_decode(token);
                                localStorage.setItem('emaildomain', decodedToken.data.user.username.split("@")[1])
                                dispatch(setPermissions({ ...decodedToken.data.user, isauth: "1" }));
                                dispatch(setUserLoggedIn(decodedToken.data.user));
                                setLoading(false);
                            }
                            else {
                                RedirectToLogin();
                            }
                        })
                        .catch(error => {
                            RedirectToLogin();
                        })

                }

        } catch (e) {
            let ee = e;
            RedirectToLogin();
            Swal.fire(ee.response.data.msg || "Failed to login", "", "error");
        }

    }, [pending]);
    const RedirectToLogin = () => {
        window.location.replace(`${BASE_URL}/login.php`);
    }

    
    return (
        <Fragment>
            
            {(permissions.isauth === "1" || isauth === "1") ? (
                <Layout  >
                    <BrowserRouter>
                        <Routes>
                            <Route exact path="/" element={<FormsList pending={pending} setPending={(pending) => { setPending(pending) }} recentResponses={recentResponse} responses={responses} user={user} survey={surveys} />} />
                            <Route path="/add-form" exact element={<NewForm pending={pending} setPending={(pending) => { setPending(pending) }} />} />
                            <Route exact path="/Responses/:id" element={<MyForms user={user} surveys={responses} pending={pending} setPending={(pending) => { setPending(pending) }} />} />
                            <Route path="/myforms/:id" exact element={<ViewForm pending={pending} setPending={(pending) => { setPending(pending) }} />} />
                            <Route path="/Summary/:id" exact element={<Summary />} />
                            <Route path="/Response/:id" exact element={<ViewResponse />} />
                            <Route path="/editforms/:id" exact element={<EditForm />} />
                            <Route path="/details-question/:id/:questionId/:questionName" exact element={<DetaileFormView />} />
                            <Route path="/Publish/:id" exact element={<Publish />} />
                        </Routes>
                    </BrowserRouter>
                </Layout>) : (
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<h2>404 Page Not Found</h2>} />
                    </Routes>
                </BrowserRouter>
            )}
            <BrowserRouter>
                <Routes>
                    <Route path="/mypublished/:id" exact element={<PublishForms pending={pending} setPending={(pending) => { setPending(pending) }} />} />
                </Routes>
            </BrowserRouter>
        </Fragment>

    );

};

export default App;
