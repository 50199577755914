import React from 'react';
// import PropTypes from 'prop-types';
// CSS
import 'suneditor/dist/css/suneditor.min.css';
// core components
import SunEditorElement from 'suneditor-react';

function SunEditor(props) {

  return (
    <div >
                 <SunEditorElement name={props.Name} setContents={props.setContents} setOptions={{
                                    buttonList: [
                                        [
                                            "bold",
                                            "underline",
                                            "italic",
                                            "strike",
                                            "list",
                                            "align",
                                            "fontSize",
                                            "formatBlock",
                                            "table",
                                            "image",
                                            "video",
                                            "fullScreen"
                                        ]
                                    ]
                                }} onChange={props.onChange} setDefaultStyle="font-size:25px; height:200px;" />

    </div>
  );
}



export default SunEditor ;
