import React from 'react';
import classes from './Toolbar.module.css';
import Logo from '../../Logo/Logo';
import NavigationItems from '../NavigationItems/NavigationItems';
import DrawerToggle from '../SideDrawer/DrawerToggle/DrawerToggle';
import useCheckSuperAdmin from '../../../hooks/useCheckSuperAdmin';
import SchoolSelectInfoBar from '../../UI/SchoolSelectInfoBar';
const Toolbar =(props)=>{
  const ifSuperAdmin = useCheckSuperAdmin();
  let margin = "0px";
  if(ifSuperAdmin) margin = "60px";
    return(
      <>
          <div style={{
            background: 'rgb(255, 193, 7)',
                padding: '0px',
                position: 'fixed',
                left: '0',
                top: '0',
                width: '100%',
               zIndex :"1000",
                justifyContent: 'space-around',
               
              }}>
                {ifSuperAdmin && <SchoolSelectInfoBar />}


            </div>
        <header className={classes.Toolbar} style={{marginTop :margin}}>
        <DrawerToggle clicked={props.drawerToggleClicked}/>
        <Logo height="80%"/>
        <nav className={classes.DesktopOnly}>
          <NavigationItems/>
        </nav>

    </header>
              </>
    )

    };
export default Toolbar;


