import React, { useEffect, useState } from 'react';

import axios from 'axios';
import Select from 'react-select';

import useCheckSuperAdmin from '../../hooks/useCheckSuperAdmin';



const SchoolSelectInfoBar = () => {
    // const [schoolSelectOptions, setSchoolSelectOptions] = useState<any>(
    //     [
    //         { id: '1', label: 'Fathalla', value: 'fathalla.iatseg.org' },
    //         { id: '2', label: 'Fresh', value: 'fresh.iatseg.org' },
    //         { id: '3', label: 'Sweedy', value: 'sweedy.iatseg.org' },
    //         { id: '4', label: 'Deifalla', value: 'deifalla.iatseg.org' },
    //     ]
    // )
    const schoolSelectOptions =
        [
            { id: '1', label: 'Fathallah Modern Trade', value: 'alex.iatseg.org' },
            // { id: '2', label: 'Fresh', value: 'fresh.iatseg.org' },
            // { id: '3', label: 'Sweedy', value: 'sweedy.iatseg.org' },
            { id: '4', label: 'Ahmed Diefalla AI', value: 'ai.iatseg.org' },
        ]

    const [selectedSchool, setSelectedSchool] = useState()
    const userData = useCheckSuperAdmin();
    console.log(userData);


    useEffect(() => {
        const schoolLocallyStored = localStorage.getItem("selected_school");
        if (schoolLocallyStored) {
            setSelectedSchool(JSON.parse(schoolLocallyStored))
            axios.defaults.headers.common['emaildomain'] = `${JSON.parse(schoolLocallyStored).value}`;

        } else {
            setSelectedSchool(schoolSelectOptions[0])
            localStorage.setItem('selected_school', JSON.stringify(schoolSelectOptions[0]))
            window.location.reload()
            axios.defaults.headers.common['emaildomain'] = `${schoolSelectOptions[0].value}`;

        }
    }, [userData])

    return (
        <div style={{ background: '#ffc107', display: 'flex', alignItems: 'baseline', width: '100%' }}>
            <h5 style={{ fontSize: '1.2rem', fontWeight: 'bold' }} >
                You Are Currently Viewing As
            </h5>
            <div style={{ marginBottom: "10px", marginTop: "10px", marginLeft: "10px", flex: '0.8', zIndex: '10', fontSize: '1.2rem', fontWeight: 'bold' }}>
                {/* <InputLabel
                    htmlFor=""
                    style={{ color: "#0c50a7", marginBottom: "10px", fontWeight: "bold" }}
                >
                    {t.translate("Chnage School")}:
                </InputLabel> */}
                <Select
                    options={schoolSelectOptions}
                    onChange={(value) => {
                        setSelectedSchool(value);
                        localStorage.setItem('selected_school', JSON.stringify(value))
                        window.location.reload()
                    }}
                    placeholder={"Select School"}
                    value={selectedSchool}
                />
            </div>

        </div>
    )
}

export default SchoolSelectInfoBar