import React, { Fragment, useEffect, useState } from "react";
import classes from "./Summary.module.css";
import Card from "@mui/material/Card";
import { Row } from "reactstrap";
import Input from "../../components/Input/Input";
import axios from "axios";
import { useParams, Link } from "react-router-dom";
import { useNavigate } from "react-router";
import DoughnutChart from "../../components/DoughnutChart/DoughnutChart";
import BarChart from "../../components/BarChart/BarChart";
import { BASE_URL } from "../../variables/api";

const Summary = (props) => {
  let navigate = useNavigate();
  const id = useParams();
  const [formData, setFormData] = useState({
    name: "",
    FormArr: [],
    description: "",
  });

  useEffect(() => {
    axios.post(`${BASE_URL}/surveys/getsummary.php`, id).then((res) => {
      setFormData((prevstate) => ({
        ...prevstate,
        name: res.data.data.summary[0].name,
        FormArr: res.data.data.responses,
      }));
    });
  }, []);

  let data = {};
  let labels = {};
  formData.FormArr?.map((persons) => {
    persons?.map((response) => {
      const xaxis = response?.options?.map((option) => {
        if (
          response.type.value === "radio" ||
          response.type.value === "linescale" ||
          response.type.value === "select"
        ) {
          return option?.value;
        } else if (response.type.value === "checkbox") {
          if (option.value?.split(":")[0]?.length <= 20) {
            return option.value?.split(":")[0];
          } else if (
            option.value?.split(" ")[0] !== null ||
            option.value?.split(" ")[1] !== null ||
            option.value?.split(" ")[2]
          ) {
            return (
              option?.value?.split(" ")[0] +
              option?.value?.split(" ")[1] +
              option?.value?.split(" ")[2]
            );
          } else {
            return option?.value;
          }
        }
      });
      if (!labels[response.id]) {
        labels[response.id] = xaxis;
      }
      const yaxis = response?.options.map((option) => option?.id);
      if (!data[response.id]) {
        data[response.id] = yaxis.reduce((a, v) => ({ ...a, [v]: 0 }), {});
      }
      response?.options?.map((option) => {
        if (option.response === true) {
          ++data[response.id][option.id];
        }
      });
    });
  });

  let backrgroundColor = [
    "#FF6633",
    "#FFB399",
    "#FF33FF",
    "#FFFF99",
    "#00B3E6",
    "#E6B333",
    "#3366E6",
    "#999966",
    "#99FF99",
    "#B34D4D",
    "#80B300",
    "#809900",
    "#E6B3B3",
    "#6680B3",
    "#66991A",
    "#FF99E6",
    "#CCFF1A",
    "#FF1A66",
    "#E6331A",
    "#33FFCC",
    "#66994D",
    "#B366CC",
    "#4D8000",
    "#B33300",
    "#CC80CC",
    "#66664D",
    "#991AFF",
    "#E666FF",
    "#4DB3FF",
    "#1AB399",
    "#E666B3",
    "#33991A",
    "#CC9999",
    "#B3B31A",
    "#00E680",
    "#4D8066",
    "#809980",
    "#E6FF80",
    "#1AFF33",
    "#999933",
    "#FF3380",
    "#CCCC00",
    "#66E64D",
    "#4D80CC",
    "#9900B3",
    "#E64D66",
    "#4DB380",
  ];
  return (
    <Fragment>
      <Link className="btn btn-secondary mb-3" to="/">
        <i className="fa fa-arrow-left" aria-hidden="true"></i>
      </Link>

      <h2 className={classes.h2}>{formData.name || "no responses"} Form</h2>
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <Card className="col-md-8">
          <div>
            {formData.FormArr?.map((t, index) => {
              if (index === 0) {
                return t?.map((details, i) => {
                  console.log(details)
                  if (details.type.value === "checkbox") {
                    return (
                      <div
                        key={`main-${i}`}
                        className="text-center"
                        style={{ marginLeft: "5%" }}
                      >
                        <div>{details.Question}</div>

                        <BarChart
                          labels={labels[details.id]}
                          stepSize={"1"}
                          min={-100}
                          max={100}
                          display={true}
                          text={details.Question}
                          datasets={details.options?.map((option, index) => {
                            let datatemp = [];
                            details.options.map((item) => datatemp.push(0));
                            datatemp[index] =
                              data[details.id][`option-${index + 1}`];
                            return {
                              label: option.value,
                              data: datatemp,
                              backgroundColor: backrgroundColor[index],
                            };
                          })}
                        />
                      </div>
                    );
                  } else if (
                    details.type.value === "linescale" ||
                    details.type.value === "select"
                  ) {
                    return (
                      <div
                        key={`main-${i}`}
                        className="text-center"
                        style={{ marginLeft: "5%" }}
                      >
                        <div>{details.Question}</div>

                        <BarChart
                          labels={labels[details.id]}
                          stepSize={"1"}
                          min={-100}
                          max={100}
                          display={true}
                          text={details.Question}
                          datasets={[
                            {
                              label: details.Question,
                              data: Object.values(data[details.id]),
                              backgroundColor: ["RGB(50, 120, 189)"],
                            },
                          ]}
                        />
                      </div>
                    );
                  } else if (details.type.value === "radio") {
                    return (
                      <div
                        key={`linescale-${i}`}
                        style={{
                          marginLeft: "5%",
                          padding: "5px",
                          border: "1px solid",
                          marginBottom: "2%",
                        }}
                      >
                        <div>{details.Question}</div>

                        <BarChart
                          labels={labels[details.id]}
                          stepSize={"1"}
                          min={-100}
                          max={100}
                          display={true}
                          text={details.Question}
                          datasets={[
                            {
                              label: details.Question,
                              data: Object.values(data[details.id]),
                              backgroundColor: ["RGB(50, 120, 189)"],
                            },
                          ]}
                        />
                      </div>
                    );
                  }
                });
              }
            })}
          </div>
        </Card>
      </div>
    </Fragment>
  );
};
export default Summary;
