import { createStore, applyMiddleware, Store, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import rootReducer from "./reducers/root.reducer";

let global ={
     Window :{
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: typeof compose
    }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store= createStore(rootReducer, composeEnhancers(applyMiddleware(
    thunkMiddleware
)));

export default store;