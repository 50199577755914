import React,{Fragment,useEffect,useState} from 'react';
import classes from './DetailedFormView.module.css';
import Card from '@mui/material/Card';
import Input from '../../components/Input/Input';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router';
import { BASE_URL } from '../../variables/api';

const MyForms =(props)=>{
    let navigate = useNavigate();
    const { id , questionId ,questionName} = useParams();
    const [formData,setFormData]= useState({name:'' ,FormArr:[],description:'' });
    const [responseData,setResponseData]= useState({Questionid:'',response:'' });
    const [option,setOptions]= useState();
    const handleClickDetailedView =(questionName)=>{
        navigate(`/details-question/${id.id}/${questionName}`)
    }

    useEffect(() => {
        axios.post(`${BASE_URL}/surveys/getresponses.php`,{id:id}).then((res)=>{

            if(res.data.data.length===0){
                Swal.fire({
                    title: 'No submitted responses available for this form yet',
                    confirmButtonText: `ok`,
                  }).then((result) => {
                      /* Read more about isConfirmed, isDenied below */
                      if (result.isConfirmed) {
                        navigate(`/`)
                      }
                  });
            }
       setFormData(prevstate=>({
           ...prevstate,
           FormArr:res.data.data
    }))
    // const options= res.data.data[0].options.map((option)=>{
    //     return { value:option.value, label: `${option.value} ` };
    // })
    // setOptions(options);
        });
    },[]);

    const submitHandler=(e)=>{
        e.preventDefault();
        axios.post(`${BASE_URL}/surveys/addresponse.php`,{formData:formData}).then((res)=>{
            Swal.fire("Response submitted Successfully", "", "success");

        }).finally(()=>setResponseData({Questionid:'',response:'' }));
    }
    return(
        <Fragment>
            <h2 className={classes.h2}>{questionName}  Question</h2>
                <div style={{display:"flex",justifyContent:"space-evenly"}} >

                         <Card className="col-md-11"  >

                         <h2>{questionName}</h2>
                         <div className='m-3 mx-5 px-5' dangerouslySetInnerHTML={{__html: formData.description}}></div>
                         <form onSubmit={submitHandler}>

                            {formData.FormArr.map((t,position)=>{
                                return(
                                t.map((details,i)=>{
                                if(questionId===details.id){
                                    if((details.type.value==="checkbox")||(details.type.value==="radio")){
                                        return(
                                            <div key={`checkboxes-${i}`} style={{marginLeft:"5%" ,padding:"5px",border:"1px solid",marginBottom:"2%"}}>
                                            <div >{details.Question}</div>
                                            <div style={{textAlign:"center"}}>
                                           { details.options.map((option,index)=>{
                                               console.log(`${details.Question}-${i}-${position}`)
                                                return(
                                                    <div key={`responses-${index}-${i}`} className="form-check-inline">
                                                        <Input className="form-check-input" disabled  name={`${details.Question}-${i}-${position}`}type={details.type.value} defaultChecked={option.response?true:false} description={details.description}  />
                                                        <label className="form-check-label">{option.value}</label>
                                                    </div>
                                                      )
                                            })}
                                            </div>
                                            </div>
                                        )

                                    }
                                    else if(details.type.value==="linescale"){
                                        return(
                                            <div key={`linescale-${i}`} style={{marginLeft:"5%" ,padding:"5px",border:"1px solid",marginBottom:"2%"}}>
                                               <div>{details.Question}</div>
                                               <div style={{textAlign:"center"}}>
                                               { details.options.map((option,index)=>{
                                                return(
                                                    <div className="form-check-inline" key={`responses-${index}-${i}`} >
                                                        <Input  disabled className="form-check-input" style={{marginTop:"-2%"}} name={`${details.Question}-${i}-${position}`} type="radio" required={details.required} description={details.description}   value={option.value} defaultChecked={option.response} />
                                                        <label className="form-check-label">{option.value}</label>
                                                    </div>
                                                      )
                                                 })
                                    }
                                               </div>
                                            </div>
                                              )
                                    }
                                    else if(details.type.value==="select"){
                                        return(
                                            <div key={`select-${i}`} style={{marginLeft:"5%" ,padding:"5px",border:"1px solid",marginBottom:"2%"}}>
                                                <Input type={details.type.value} disabled label={details.Question} value={details.response}  required={true} options={option}  />
                                            </div>

                                              )
                                    }
                                    else if(details.type.value==="file"){
                                        if(t.response != {})
                                        {
                                         return(
                                             <div key={`file-${i}`} style={{marginLeft:"5%" ,padding:"5px",border:"1px solid",marginBottom:"2%"}}>
                                               <a href={JSON.parse(details.response).driveid.includes("https://")?JSON.parse(details.response).driveid:`https://drive.google.com/uc?id=${JSON.parse(details.response).driveid}&export=download`}> {JSON.parse(details.response).fileName}</a>
                                             </div>

                                               )

                                        }
                                        else{
                                            return(
                                                <div key={`file-${i}`} style={{marginLeft:"5%" ,padding:"5px",border:"1px solid",marginBottom:"2%"}}>
                                                    <Input type={details.type.value} label={details.Question} disabled  onChange={(event)=>{}}/>
                                                </div>

                                                  )
                                        }
                                  }
                                    else{
                                        return(
                                            <div key={`other-${i}`} style={{marginLeft:"5%" ,padding:"5px",border:"1px solid",marginBottom:"2%"}}>
                                                <Input type={details.type.value} label={details.Question} disabled value={details.response}  required={true}  />
                                            </div>

                                              )
                                    }

}
                                })




                                )
})}

</form>
                            </Card>

                </div>
        </Fragment>


    )

    };
export default MyForms;


