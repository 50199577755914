import { Bar } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import classes from "./BarChart.module.css";
Chart.register(...registerables);

function BarChart(props) {
  const data = {
    labels: props.labels,
    datasets: props.datasets,
  };
  const options = {
    plugins: {
      legend: {
        title: { padding: 0 },
        labels: {
          textAlign: "center",
          font: {
            size: 9,
          },
        },
      },
    },
    title: {
      display: props.display,
      text: props.text,
    },
    scales: {
      y: {
        suggestedMin: 0,
        suggestedMax: 10,
        stepSize: 1,
      },
    },
  };
  return <Bar className={classes.Chart} data={data} options={options} />;
}
export default BarChart;
