import { combineReducers } from "redux";

import authReducer from "./auth.reducer";
import userReducer from "./userReducer";

const rootReducer= combineReducers({
    permissions: authReducer,
    user :userReducer
});

export default rootReducer;