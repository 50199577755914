import React, { Fragment, useState, useEffect } from "react";
import classes from "./MyForms.module.css";
import Card from "@mui/material/Card";
import CircleIcon from "@mui/icons-material/Circle";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { Row, Col } from "reactstrap";
import { useNavigate } from "react-router";
import axios from "axios";
import { useParams, Link } from "react-router-dom";

import Swal from "sweetalert2";
import { BASE_URL } from "../../variables/api";
const MyForms = (props) => {
  let navigate = useNavigate();
  const [surveys, setSurveys] = useState([]);
  const id = useParams();
  useEffect(() => {
    axios
      .get(`${BASE_URL}/surveys/getallresponsesforspecificsurvey.php`)
      .then((res) => {
        setSurveys(res.data.data);
      });
  }, [id]);

  const [index, setIndex] = useState(0);
  const handleView = (id) => {
    navigate(`/Response/${id}`);
  };
  const handleDelete = (id) => {
    Swal.fire({
      showDenyButton: true,
      title: "Do you want to Delete Response?",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: `YES`,
      denyButtonText: `Cancel`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        Swal.fire("Saved!", "", "success");
        axios
          .post(`${BASE_URL}/surveys/deleteresponse.php`, { id: id })
          .then((res) => {
            props.setPending(!props.pending);
          });
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };
  let filteredresponses = surveys.filter((m) => id.id === m.survey_id);

  return (
    <Fragment>
      <Link className="btn btn-secondary mb-3" to="/">
        <i className="fa fa-arrow-left" aria-hidden="true"></i>
      </Link>

      <h2 className={classes.h2}> Responses</h2>
      <div>
        <Card className={classes.Card}>
          <div style={{ textAlign: "center" }}>
            {id.id === ":id" ? (
              <h1 className={classes.h1}>{surveys.length}</h1>
            ) : (
              <h1 className={classes.h1}>{filteredresponses.length}</h1>
            )}
            <p className={classes.p}>Total Responses</p>
          </div>
          <Row style={{ border: "1px solid" }} className={classes.Row}>
            <Col>
              <span>Survey Name</span>
            </Col>
            <Col>
              <span className={classes.info}> Answered by</span>
            </Col>
            <Col>
              <span className={classes.info}>Owner</span>
            </Col>
            <Col>
              <span className={classes.info}>Date</span>
            </Col>
            <Col>
              <span className={classes.info}>Status</span>
            </Col>
            <Col>
              <span className={classes.info}>Actions</span>
            </Col>
          </Row>
          {surveys.map((t, index) => {
            if (id.id === t.survey_id) {
              return (
                <Row key={`t.name-${index}`} className={classes.Row}>
                  <Col>
                    <span className={classes.info}>{t.survey_name}</span>
                  </Col>
                  <Col>
                    <span className={classes.info}> {t.answeredby}</span>
                  </Col>
                  <Col>
                    <span className={classes.info}> {`${t.owner}`}</span>
                  </Col>
                  <Col>
                    <span className={classes.info}> {`${t.date}`}</span>
                  </Col>
                  <Col>
                    <span className={classes.info}> {`Submitted`}</span>
                  </Col>
                  <Col>
                    <RemoveRedEyeIcon
                      onClick={() => {
                        handleView(t.response_id);
                      }}
                      className={classes.Icon}
                    />
                    <DeleteIcon
                      onClick={() => {
                        handleDelete(t.response_id);
                      }}
                      className={classes.Icon}
                    />
                  </Col>
                </Row>
              );
            } else if (id.id === ":id") {
              return (
                <Row key={`t.name-${index}`} className={classes.Row}>
                  <Col>
                    <span className={classes.info}>{t.survey_name}</span>
                  </Col>
                  <Col>
                    <span className={classes.info}> {t.answeredby}</span>
                  </Col>
                  <Col>
                    <span className={classes.info}> {`${t.owner}`}</span>
                  </Col>
                  <Col>
                    <span className={classes.info}> {`${t.date}`}</span>
                  </Col>
                  <Col>
                    <span className={classes.info}> {`Submitted`}</span>
                  </Col>
                  <Col>
                    <RemoveRedEyeIcon
                      onClick={() => {
                        handleView(t.response_id);
                      }}
                      className={classes.Icon}
                    />
                    <DeleteIcon
                      onClick={() => {
                        handleDelete(t.response_id);
                      }}
                      className={classes.Icon}
                    />
                  </Col>
                </Row>
              );
            }
          })}
        </Card>
      </div>
    </Fragment>
  );
};
export default MyForms;
