import React from 'react';
import classes from './RecentResponse.module.css';
import Card from '@mui/material/Card';
import CircleIcon from '@mui/icons-material/Circle';
//import {format, set}from 'date-fns';

const RecentResponse =(props)=>{
    let temparr=["Survey name","Responeses Recieved","Individuals","Status"];
    let recentResponses=[];
    let Individuals=[];
    let recentResponsesnumber=0;
    recentResponses=props.recentResponses;
    function removeDuplicateArrayValues(arr) {
        return arr.filter((value, index) => (
          arr.indexOf(value) === index
        ));
      }
     
    let lastsurvey= props.survey.map((m)=>{if(m.id===props.survey[0].id){return m}});
   
   lastsurvey.map((m)=>{
    recentResponses.map((r)=>{
           if(m!==undefined){
               if(m.id===r.survey_id)
               {
                ++recentResponsesnumber
                Individuals.push(r.answeredby)
               }

           }
       })
   })


   
   let displayindividual=removeDuplicateArrayValues(Individuals)


   let displayarr=[props.survey[0]?.name,recentResponsesnumber,Number(displayindividual.length),"Submitted"]


    return(

<div className={classes.Page}>

            <Card className={classes.Card}>
            {temparr.map((t,index)=>{

                    return(
                        <div className={classes.Row} key={t}>
                            {`${t} :`}
                            <span style={{marginLeft:"6px"}}>
                            {displayarr[index]}
                            </span>
                        </div>
                    )
            })}


            </Card>
            <Card className={classes.Card1}>

               <h2 className={classes.h2}>{props.responses.length}</h2>
                <p className={classes.p}> Total responses of all surveys </p>
                <p className={classes.pg}></p>

            </Card>
</div>

    )

    };
export default RecentResponse;


