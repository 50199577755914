import React, { useState, useEffect } from "react";
import classes from "./Publish.module.css";
import Card from "@mui/material/Card";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Container, Row, Col, CardFooter } from "reactstrap";
import Input from "../../components/Input/Input";
import DeleteIcon from "@mui/icons-material/Delete";
import Switch from "@mui/material/Switch";
import CircleIcon from "@mui/icons-material/Circle";
import axios from "axios";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import { BASE_URL } from "../../variables/api";
import { useNavigate } from "react-router";
import SunEditor from "../../components/Suneditor/SunEditor";
import { SplitButton } from "react-bootstrap";

const Publish = (props) => {
  let navigate = useNavigate();
  const id = useParams();

  const [formData, setFormData] = useState({
    id: id.id,
    sendfrom: "",
    sendto: [],
    filter: "",
    grades: [],
    departments: [],
    subject: "",
    body: "",
    cc: "",
    Attachment: [],
    publishedby: "",
    filtereddept: "",
    filteredgrades: "",
  });
  const [sendfrom, setSendFrom] = useState({});
  const [file, setFile] = useState(null);
  const [count, setCount] = useState(0);
  const [filter, setFilter] = useState(false);
  const [Departments, setDepartments] = useState(false);
  const [gradesOptions, setGradesOptions] = useState([]);
  const [departmentsOptions, setDepartmentsOptions] = useState([]);
  let sentToOptions = [
    {
      value: "Students_personal_Email",
      label: "Students personal Email",
      response: "",
    },
    { value: "Students_IVY_Email", label: "Students  Email", response: "" },
    { value: "Parents", label: "Parents", response: "" },
    { value: "Test_Email", label: "Test Email", response: "" },
    { value: "IVY_All_Staff", label: "All Staff", response: "" },
    { value: "Departments", label: "Departments", response: "" },
    // {value:"Admission_Team",label:"Admission Team",response:''},
    // {value:"Administration",label:"Administration",response:''}
  ];
  var filtereddept = "";
  useEffect(() => {
    axios
      .post(`${BASE_URL}/surveys/getpublishedsurvey.php`, { id: id.id })
      .then((res) => {
        let publishedSurvey = res.data.data;
        console.log(publishedSurvey);
        if(publishedSurvey === null || publishedSurvey === undefined)return;
        let sendFrom = options.find((o) => o.value == publishedSurvey.sendfrom);
        setSendFrom(sendFrom);
        setFormData((prevState) => ({
          ...prevState,
          subject: publishedSurvey.subject??"",
          body: publishedSurvey.body??"",
          cc: publishedSurvey.cc??"",
          sendfrom: publishedSurvey.sendfrom??"",
          Attachment: publishedSurvey.attachments??"",
          publishedby: publishedSurvey.publishedby??"",
          filtereddept: publishedSurvey.filterdept??"",
          filtergrades: publishedSurvey.filtergrades??"",
          filter: publishedSurvey.filtergrades !== "" ? true : false,
        }));
        let filtered = publishedSurvey.sendto?.split(",")?.map((m) => m);
        sentToOptions?.map((option, index) => {
          if (
            option.value?.search(
              filtered.filter((f) => f === option.value)[0] !== undefined
                ? filtered.filter((f) => f === option.value)[0]
                : " "
            ) !== -1
          ) {
            option.response = true;
            formData.sendto.push(option);
          }
        });
        setFormData((prevstate) => ({ ...prevstate, sendto: formData.sendto }));
        let sendto = formData.sendto;
        let personalEmail = sendto.find((x)=>x.value === 'Students_personal_Email');
      let ivyemail = sendto.find((x)=>x.value === 'Students_IVY_Email');
      let parentE = sendto.find((x)=>x.value === 'Parents');
      let Dep = sendto.find((x)=>x.value === 'Departments');
      if( (personalEmail && personalEmail.response) || (ivyemail && ivyemail.response) || (parentE && parentE.response) ){
        setFilter(true);
      }else{
        setFilter(false);
      }
      if( (Dep && Dep.response)  ){
        setDepartments(true);
      }else{
        setDepartments(false);
      }

      });
  }, []);
  useEffect(() => {
    axios.get(`${BASE_URL}/surveys/getgrades.php`).then((res) => {
      const gradeOptions = res.data.data?.grades.map((option) => {
        return { value: option.id, label: `${option.grade} ` };
      });
      const departmentOptions = res.data.data?.departments.map((option) => {
        return { value: option.id, label: `${option.department} ` };
      });
      setGradesOptions(gradeOptions);
      setDepartmentsOptions(departmentOptions);
    });
  }, []);


  const handleChangeAttachement = (e, id) => {
    const name = e.target.name;
    const value = e.target.value;
    let attachements = [...formData.Attachment];
    let index = attachements.findIndex((a) => a.id === id);
    if ("file" == name) {
      if (e.target.files === null) return;
      const selectedFile = e.target.files[0];
      setFile(selectedFile);
      attachements[index].file = selectedFile;
    }
    setFormData((prevState) => ({ ...prevState, Attachment: attachements }));
  };
  const handleRemoveattachment = (id) => {
    axios
      .post(`${BASE_URL}/surveys/deleteattachment.php`, { id: id })
      .then((res) => {
        let index = formData.Attachment.findIndex((a) => a.id === id);
        formData.Attachment.splice(index, 1);
        setFormData((prevState) => ({
          ...prevState,
          Attachment: formData.Attachment,
        }));
      });
  };
  const handleSubmitTicket = (e) => {
    e.preventDefault();
    Swal.showLoading();
    console.log(formData);
    const surveyData = new FormData();
    Object.entries(formData).forEach(([key, value]) =>
      key === "Attachment" && Array.isArray(value)
        ? value.forEach((attachment) => {
            surveyData.append(attachment.id, attachment.file);
          })
        : Array.isArray(value)
        ?  surveyData.append(key, JSON.stringify(value))
        : surveyData.append(key, value)
    );

    const res = axios
      .post(`${BASE_URL}/surveys/savemail.php`, surveyData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setFormData((prevstate) => ({
          ...prevstate,
          id: "",
          name: "",
          sendfrom: "",
          sendto: [],
          filter: "",
          grades: [],
          departments: [],
          subject: "",
          body: "",
          cc: "",
          Attachment: [],
        }));
        setCount(0);

        Swal.fire("Published Successfully", "", "success");
        navigate("/");
      })
      .catch((e) => {
        let ee = e;

        Swal.fire(ee?.response?.data.msg || "Failed to upload", "", "error");
      });
  };
  const addAttachement = (e) => {
    e.preventDefault();
    formData.Attachment.push({ id: `attachment-${count}`, file: {} });
    setCount(count + 1);
  };
  let options = [];
 if(window.location.href.search("iatseg.org") != -1){

    options = [
     { value: "Survey", label: "Survey@iatseg.org" },
    
     { value: "noreply", label: "no-reply@iatseg.org" },
    
   ];
 }else{

    options = [
     { value: "Survey", label: "Survey@ivyis.org" },
     { value: "admission", label: "Admission@ivyis.org" },
     { value: "hr", label: "Hr@ivyis.org" },
     { value: "noreply", label: "no-reply@ivyis.org" },
     { value: "cafe", label: "Cafeteria@ivyis.org" },
     { value: "club", label: "Club@ivyis.org" },
     { value: "activity", label: "Activity@ivyis.org" },
     { value: "academic", label: "Academic@ivyis.org" },
     { value: "studentaffairs", label: "StudentAffairs@ivyis.org" },
     { value: "principal", label: "Principal@ivyis.org" },
     { value: "lowergrade", label: "LowerGrade@ivyis.org" },
     { value: "transportation", label: "transportation@ivyis.org" },
     { value: "ceo", label: "CEO@ivyis.org" },
     { value: "drama", label: "Drama@ivyis.org" },
     { value: "staffdevelopment", label: "Staffdevelopment@ivyis.org" },
   ];
 }

  const handleSuneidtorChange = (event) => {
    setFormData((prevstate) => ({ ...prevstate, body: event }));
  };
  const handleSelectChange = (event) => {
    setSendFrom(event);
    setFormData((prevstate) => ({ ...prevstate, sendfrom: event.value }));
  };
  const handleSelectGradesChange = (event) => {
    setFormData((prevstate) => ({ ...prevstate, grades: event }));
  };
  const handleSelectDepartmentsChange = (event) => {
    setFormData((prevstate) => ({ ...prevstate, departments: event }));
  };
  const handleChange = (event) => {
    const name = event.target.name;
    const type = event.target.type;
    const value = event.target.value;
    const checked = event.target.checked;
    console.log(name, type, value,checked);
    if (name === "sendto") {
      let sendto = [...formData.sendto]
      let index = sendto.indexOf((o)=> o.value === event.target.value);
      if(index === -1){
        sendto.push({value: event.target.value, label: event.target.value, response: event.target.checked});
      }else{
        sendto[index].values = event.target.checked;
      }     
      /*      
      {value: 'Students_personal_Email', label: 'Students personal Email', response: ''}
      {value: 'Students_IVY_Email', label: 'Students IVY Email', response: true}
      {value: 'Parents', label: 'Parents', response: true}
      {value: 'Test_Email', label: 'Test Email', response: ''}
      {value: 'IVY_All_Staff', label: 'IVY All Staff', response: ''}
      {value: 'Departments', label: 'IVY Departments', response: ''} */
      let personalEmail = sendto.find((x)=>x.value === 'Students_personal_Email');
      let ivyemail = sendto.find((x)=>x.value === 'Students_IVY_Email');
      let parentE = sendto.find((x)=>x.value === 'Parents');
      let Dep = sendto.find((x)=>x.value === 'Departments');
      if( (personalEmail && personalEmail.response) || (ivyemail && ivyemail.response) || (parentE && parentE.response) ){
        setFilter(true);
      }else{
        setFilter(false);
      }
      if( (Dep && Dep.response)  ){
        setDepartments(true);
      }else{
        setDepartments(false);
      }
        setFormData((prevstate) => ({ ...prevstate, sendto: sendto }));
    } else if (type === "checkbox") {
      setFormData((prevstate) => ({ ...prevstate, [name]: checked }));
    } else {
      setFormData((prevstate) => ({ ...prevstate, [name]: value }));
    }
  };
  console.log("seb",formData)
  return (
    <Container className="container">
      <Link className="btn btn-secondary mb-3" to="/">
        <i className="fa fa-arrow-left" aria-hidden="true"></i>
      </Link>
      <h2>Create email</h2>
      <form onSubmit={handleSubmitTicket}>
        <p>Sent from: </p>
        <Input
          type="select-1"
          required
          className={classes.select}
          options={options}
          name="sendfrom"
          value={sendfrom}
          onChange={(event) => {
            handleSelectChange(event);
          }}
        />
        <p>Send to: </p>
        <div className="row">
          {sentToOptions.map((option, index) => {
            return (
              <div
                style={{
                  marginLeft: "5%",
                }}
                className="form-check-inline "
                key={index}
              >
                <span style={{ textAlign: "center", marginTop: "12%" }}>
                  
                    <Input
                      className="form-check-input"
                      id={option.value}
                      type="checkbox"
                      name="sendto"
                      onChange={(event) => {
                        handleChange(event);
                      }}
                      value={option.value}
                      checked={formData.sendto.find(
                        (f) => f.response === true && f.value === option.value
                      )}
                    />
                  
                </span>
                <label className="form-check-label" htmlFor={option.value}>
                  {option.label}
                </label>
              </div>
            );
          })}
        </div>
        {Departments === true? (
          <div style={{zIndex:300,position:"relative"}}>
            <p style={{ marginTop: "2%" }}> Departments:</p>
              <Input
                style={{ minWidth: "30%" }}
                placeholder="Select Departments"
                type="select-1"
                name="departments"
                className={classes.select}
                value={formData.departments}
                isMulti={true}
                isClearable
                options={departmentsOptions}
                onChange={(event) => {
                  handleSelectDepartmentsChange(event);
                }}
              />
          </div>
        ):null}

        {filter === true && (
          <div>
            <p style={{ marginTop: "2%" }}>Group:</p>
            <div style={{zIndex:300,position:"relative"}}>
              <div>
                <div className="form-check">
                  <Input
                    id="filterbygrade"
                    className="form-check-input"
                    type="checkbox"
                    name="filter"
                    value={formData.filter}
                    onChange={(event) => {
                      handleChange(event);
                    }}
                    checked={formData.filter? true : false}
                  />
                  <label className="form-check-label" htmlFor="filterbygrade">
                    {"Filter by grades"}
                  </label>
                </div>
              </div>
              {formData.filter && (
                <div>
                  {formData.publishedby === "" &&
                  formData.filteredgrades?.length === 0 ? (
                    <Input
                      style={{ minWidth: "30%" }}
                      placeholder="Select Grades"
                      type="select-1"
                      className={classes.select}
                      value={formData.grades}
                      isMulti={true}
                      isClearable
                      options={gradesOptions}
                      onChange={(event) => {
                        handleSelectGradesChange(event);
                      }}
                    />
                  ) : (
                    <Input
                      style={{ minWidth: "30%" }}
                      placeholder="Select Grades"
                      type="select-1"
                      className={classes.select}
                      value={gradesOptions?.filter(
                        (m) => formData.filtergrades?.search(m.value) !== -1
                      )}
                      isMulti={true}
                      isClearable
                      options={gradesOptions}
                      onChange={(event) => {
                        handleSelectGradesChange(event);
                      }}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        )}
        <p style={{ marginTop: "2%" }}>Subject</p>
        <Input
          type="text"
          className={classes.select}
          value={formData.subject}
          name="subject"
          required
          onChange={(event) => {
            handleChange(event);
          }}
        />
        <p style={{ marginTop: "2%" }}>Body</p>
        <SunEditor
          setContents={formData.body}
          required
          onChange={(event) => {
            handleSuneidtorChange(event);
          }}
        />
        <p style={{ marginTop: "2%" }}>Cc:</p>
        <Input
          type="text"
          className={classes.select}
          value={formData.cc}
          name="cc"
          placeholder="Example: Example1@gmail.com,Example2@gmail.com"
          onChange={(event) => {
            handleChange(event);
          }}
        />
        <button
          className="btn btn-primary"
          onClick={(e) => {
            addAttachement(e);
          }}
        >
          Add Attachment
        </button>
        <table className="table">
          <thead>
            <tr>
              <td>Attachments</td>
              <td>Actions</td>
            </tr>
          </thead>
          <tbody>
            {formData.Attachment.map((attachment, index) => {
              if (attachment?.drive_id) {
                return (
                  <tr key={attachment.id}>
                    <td>
                      <a
                        href={attachment.drive_id.includes("https://")?attachment.drive_id:`https://drive.google.com/uc?id=${attachment.drive_id}&export=download`}
                        style={{ color: "blue" }}
                      >
                        {" "}
                        {attachment.filename}
                      </a>
                    </td>
                    <td>
                      <button
                        className="btn btn-danger"
                        onClick={(e) => {
                          e.preventDefault();

                          handleRemoveattachment(attachment.id);
                        }}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                );
              } else {
                return (
                  <tr key={attachment.id}>
                    <td>
                      <Input
                        type="file"
                        name="file"
                        onChange={(e) =>
                          handleChangeAttachement(e, `${attachment.id}`)
                        }
                      />
                    </td>
                    <td>
                      <button
                        className="btn btn-danger"
                        onClick={() => handleRemoveattachment(attachment.id)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                );
              }
              // return (
              // <tr key={at.id} style={{backgroundColor:"lightgray",border:"2px solid",borderColor:"#3278bd",marginTop:"4px"}}>
              // <td>
              // Select Attachment
              // <Input type="file"  name="file"  onChange={(e)=>handleChangeAttachement(e,`attachment-${index}`)}   />
              // </td>
              // <td>
              // <button className="btn btn-danger" style={{backgroundColor:"red",marginTop:"auto"}}onClick={()=>handleRemoveattachment(at.id)} >Remove</button>
              // </td>
              // </tr>
              // )
            })}
          </tbody>
        </table>
        <button
          className="btn btn-primary"
          style={{ textAlign: "center", width: "100%" }}
          type="submit"
          disabled={formData.publishedby !== ""}
        >
          Submit
        </button>
      </form>
    </Container>
  );
};
export default Publish;
