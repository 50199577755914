import React from "react";
import classes from "./AddForm.module.css";
import Card from "@mui/material/Card";
import AddCircleIcon from "@mui/icons-material/AddCircle";

const AddForm = (props) => {
  return (
    <Card className={classes.Add} onClick={props.handleClick}>
      <AddCircleIcon style={{ color: "#3278bd" }} />
      <h3>Add New Form</h3>
    </Card>
  );
};
export default AddForm;
