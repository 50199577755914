import React from 'react';
import { Input,FormGroup, Label } from 'reactstrap';
import BaseSelect from "react-select";
import FixRequiredSelect from "./FixRequiredSelect";
import Select from "react-select";

const input =(props)=>{
    let inputElement=null;
    const Selectrequired = props => (
        <FixRequiredSelect
          {...props}
          SelectComponent={BaseSelect}
          options={props.options}
        />
      );
    switch(props.type){
        case('text'):
        inputElement=<Input type="text"{...props}/>;
        break;
        case('paragraph'):
        inputElement=<textarea className='form-control' {...props}></textarea>
        break;
        case('select-1'):
        inputElement=  <Select {...props}  />
        break;
        case('select'):
        inputElement=  <Selectrequired options={props.options} {...props} reqiured />
        break;
        case('checkbox'):
        inputElement=<Input type="checkbox" {...props}/>
        break;
        case("email"):
        inputElement=<Input type="email" {...props}/>
        break;
        case('radio'):
        inputElement=<Input type="radio" {...props}/>
        break;
        case('date'):
        inputElement=<Input type="date" {...props}/>
        break;
        case('file'):
        inputElement=<Input type="file" {...props}/>
        break;
        case('range'):
        inputElement=<Input type="range" {...props}/>
        break;
        default:
            inputElement=<Input {...props}/>
    }

    if (props.type === "checkbox" || props.type === "radio" ){
        return(
            <FormGroup>
        
                <Label check>
                    {inputElement}{' '}
                    {props.label}
                </Label>
                <p>{props.description}</p>
            </FormGroup>
        
        )
    }else{
        return(
            <FormGroup>
        
                <Label for={props.id}>{props.label}</Label>
                {inputElement}
                <p>{props.description}</p>
            </FormGroup>
        
        )

    }
};
export default input;