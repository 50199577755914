import React, { useState } from "react";
import classes from "./NewForm.module.css";
import Card from "@mui/material/Card";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Container, Row, Col, CardFooter } from "reactstrap";
import Input from "../../components/Input/Input";
import DeleteIcon from "@mui/icons-material/Delete";
import Switch from "@mui/material/Switch";
import CircleIcon from "@mui/icons-material/Circle";
import axios from "axios";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { BASE_URL } from "../../variables/api";
import { useNavigate } from "react-router";
import SunEditor, { buttonList } from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File

const NewForm = (props) => {
  let navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    FormArr: [],
    description: "",
  });
  const handleClickBack = () => {
    navigate("/");
  };
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const checked = event.target.checked;
    if (name === "required") {
      setFormData((prevstate) => ({ ...prevstate, required: checked }));
    } else {
      setFormData((prevstate) => ({ ...prevstate, [name]: value }));
    }
  };
  const handleChangeDesc = (event) => {
    setFormData((prevstate) => ({ ...prevstate, description: event }));
  };
  const submitHandler = (e) => {
    e.preventDefault();
    Swal.showLoading();
    axios
      .post(`${BASE_URL}/surveys/addsurvey.php`, { formData: formData })
      .then((res) => {
        Swal.fire("Survey Added Successfully", "", "success").then(() => {
          navigate("/");
        });
      })
      .finally(() => {
        setFormData({
          name: "",
          FormArr: [],
          required: false,
          description: "",
        });
        props.setPending(!props.pending);
      });
  };
  const makeid = (length) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  const handleChangeSelectLineScale = (event, id, name) => {
    let FormArrCopy = [...formData.FormArr];
    FormArrCopy.map((question) => {
      if (question.id === id) {
        if (name === "startnumber") {
          question.startnumber = event;
        }
        if (name === "endnumber") {
          question.endnumber = event;
        }
        let start = question.startnumber.value;
        let end = question.endnumber.value;
        if (start !== "" && end !== "") {
          for (let i = Number(start); i <= Number(end) + 1; i++) {
            let lenght = question.options.length + 1;
            question.options.push({
              id: `option-${lenght}`,
              value: i,
              response: "",
            });
          }

          let index = question.options.findIndex((i) => i.value === "");
          question.options.splice(index, 1);
        }
      }
    });
    setFormData((prevstate) => ({ ...prevstate, FormArr: FormArrCopy }));
  };

  const handleChangeSelect = (event, id) => {
    let FormArrCopy = [...formData.FormArr];
    FormArrCopy.map((question) => {
      if (question.id === id) {
        question.type = event;
      }
    });
    setFormData((prevstate) => ({ ...prevstate, FormArr: FormArrCopy }));
  };
  const handleChangeDynamic = (event, id) => {
    const name = event.target.name;
    const value = event.target.value;
    const checked = event.target.checked;
    let FormArrCopy = [...formData.FormArr];
    FormArrCopy.map((question) => {
      if (question.id === id) {
        if ("startnumber" === name) {
          question.startnumber = value;
        }
        if ("endnumber" === name) {
          question.endnumber = value;
        }
        if ("required" === name) {
          question.required = checked;
        }
        if ("Question" === name) {
          question.Question = value;
        }
        if ("type" === name) {
          question.type = value;
        }
        if ("description" === name) {
          question.description = value;
        }
      }
    });
    setFormData((prevstate) => ({ ...prevstate, FormArr: FormArrCopy }));
  };
  const handleChangeDynamicOptions = (event, id, optionNumber) => {
    const name = event.target.name;
    const value = event.target.value;
    let FormArrCopy = [...formData.FormArr];
    FormArrCopy.map((question) => {
      if (question.id === id) {
        if ("options" === name) {
          question.options.map((option) => {
            if (option.id === `option-${optionNumber}`) {
              option.value = value;
            }
          });
        }
      }
    });
    setFormData((prevstate) => ({ ...prevstate, FormArr: FormArrCopy }));
  };
  const deleteOptionHandeler = (id, optionid) => {
    let FormArrDeleteCopy = [...formData.FormArr];
    FormArrDeleteCopy.map((question) => {
      if (question.id === id) {
        let index = question.options.findIndex(
          (option) => option.id === `option-${optionid}`
        );
        question.options.splice(index, 1);
      }
    });
    setFormData((prevstate) => ({ ...prevstate, FormArr: FormArrDeleteCopy }));
  };
  const AddOptionHandeler = (e, id) => {
    e.preventDefault();

    let FormArrCopy = [...formData.FormArr];
    FormArrCopy.map((question) => {
      if (question.id === id) {
        let lenght = question.options.length + 1;
        question.options.push({ id: `option-${lenght}`, value: "" });
      }
    });
    setFormData((prevstate) => ({ ...prevstate, FormArr: FormArrCopy }));
  };
  const deleteQuestionHandeler = (id) => {
    console.log(id);
    let FormArrDeleteCopy = [...formData.FormArr];
    let index = FormArrDeleteCopy.findIndex((question) => question.id === id);
    FormArrDeleteCopy.splice(index, 1);
    setFormData((prevstate) => ({ ...prevstate, FormArr: FormArrDeleteCopy }));
  };
  const handleClick = () => {
    setFormData((prevstate) => ({
      ...prevstate,
      FormArr: formData.FormArr.concat({
        id: makeid(10),
        Question: "",
        type: "",
        description: "",
        required: false,
        startnumber: "",
        endnumber: "",
        response: "",
        options: [{ id: `option-1`, value: "" }],
      }),
    }));
  };
  let numbers = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
    { value: "9", label: "9" },
    { value: "10", label: "10" },
  ];
  let options = [
    { value: "paragraph", label: "Short Answer" },
    { value: "checkbox", label: "Multiple Choice (Multiple selection)" },
    { value: "date", label: "Date" },
    { value: "email", label: "Email" },
    { value: "file", label: "File" },
    { value: "radio", label: "Multiple Choice (Single selection)" },
    { value: "linescale", label: "Line Scale" },
  ];
  let temparr = ["Views", "Responeses Recieved", "Individuals", "Status"];
  let temparrFormNumbers = ["0", "0", "0"];
  return (
    <Container className="container">
      <Link className="btn btn-secondary mb-3" to="/">
        <i className="fa fa-arrow-left" aria-hidden="true"></i>
      </Link>
      <form onSubmit={submitHandler}>
        <Row className="row">
          <Col className="col-md-9">
            <Input
              label="Enter Form Name"
              required={true}
              value={formData.name}
              type="text"
              name="name"
              placeholder="Write New Form's Name"
              onChange={handleChange}
            />
          </Col>
        </Row>
        <Row className="row">
          <Col className="col-md-9">
            <SunEditor
              defaultValue={formData.description}
              placeholder="Enter Form Description"
              name="description"
              onChange={handleChangeDesc}
              setOptions={{
                defaultStyle: "font-family: arial; font-size: 20px;",
                height: 200,
                buttonList: buttonList.complex,
              }}
            />
          </Col>
        </Row>
        <Row>
          <h2>{formData.name}</h2>
        </Row>
        <Row></Row>
        <Row style={{ alignItems: "center" }}>
          <Col className="col-md-9">
            <div>
              <h2 className={classes.h2}>Questions</h2>
              <div>
                {formData.FormArr.map((t) => {
                  return (
                    <Card style={{ height: "auto" }} key={t.id}>
                      <Row>
                        <Col>
                          <Input
                            type="text"
                            name="Question"
                            required={true}
                            placeholder="Write Your Question"
                            value={t.Question}
                            onChange={(event) => {
                              handleChangeDynamic(event, t.id);
                            }}
                          />

                          {t.type.value === "select" ||
                          t.type.value === "checkbox" ||
                          t.type.value === "radio" ? (
                            <div style={{ marginBottom: "-100px" }}>
                              {t.options.map((option, index) => {
                                return (
                                  <div
                                    key={option.id}
                                    style={{
                                      display: "flex",
                                      marginLeft: "5px",
                                    }}
                                  >
                                    <Input
                                      id={option.id}
                                      type="text"
                                      name="options"
                                      placeholder={`add option ${index + 1}`}
                                      value={option.value}
                                      onChange={(event) => {
                                        handleChangeDynamicOptions(
                                          event,
                                          t.id,
                                          `${index + 1}`
                                        );
                                      }}
                                    />
                                    <DeleteIcon
                                      className={classes.trash}
                                      onClick={() => {
                                        deleteOptionHandeler(
                                          t.id,
                                          `${index + 1}`
                                        );
                                      }}
                                    />
                                  </div>
                                );
                              })}
                              <button
                                style={{ marginTop: "7px", marginLeft: "5px" }}
                                onClick={(e) => {
                                  AddOptionHandeler(e, t.id);
                                }}
                                className="btn btn-primary"
                              >
                                Add Option
                              </button>
                            </div>
                          ) : null}
                          {t.type.value === "linescale" ? (
                            <div>
                              <div
                                style={{
                                  display: "flex",
                                  marginLeft: "5px",
                                  justifyContent: "space-evenly",
                                  flex: "wrap",
                                }}
                              >
                                <Input
                                  id="startnumber"
                                  options={numbers}
                                  type="select-1"
                                  name="startnumber"
                                  placeholder={`Start Range`}
                                  value={t.startnumber}
                                  onChange={(event) => {
                                    handleChangeSelectLineScale(
                                      event,
                                      t.id,
                                      "startnumber"
                                    );
                                  }}
                                />
                                <Input
                                  id="endnumber"
                                  options={numbers}
                                  type="select-1"
                                  name="endnumber"
                                  placeholder={`End Range`}
                                  value={t.endnumber}
                                  onChange={(event) => {
                                    handleChangeSelectLineScale(
                                      event,
                                      t.id,
                                      "endnumber"
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          ) : null}
                        </Col>
                        <Col>
                          <Input
                            type="select-1"
                            className={classes.select}
                            options={options}
                            value={t.type}
                            onChange={(event) => {
                              handleChangeSelect(event, t.id);
                            }}
                          />
                        </Col>
                      </Row>
                      <CardFooter
                        style={{
                          display: "flex",
                          justifyContent: "right",
                          marginTop: "210px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                          }}
                        >
                          <DeleteIcon
                            className={classes.trash}
                            onClick={() => deleteQuestionHandeler(t.id)}
                          />
                          <p className={classes.p}>Optional</p>
                          <Switch
                            checked={t.required}
                            name="required"
                            onChange={(event) =>
                              handleChangeDynamic(event, t.id)
                            }
                          />
                          <p className={classes.p}>Required</p>
                        </div>
                      </CardFooter>
                    </Card>
                  );
                })}
                <Card className={classes.Add} onClick={handleClick}>
                  <AddCircleIcon style={{ color: "#3278bd" }} />
                </Card>
              </div>
            </div>
          </Col>
          <Col className="col-md-3">
            <h2 style={{ marginTop: "20px" }} className={classes.h2}>
              Responeses
            </h2>
            <Card className={classes.Card}>
              {temparr.map((t, index) => {
                if (t !== "Status") {
                  return (
                    <div className={classes.Row} key={t}>
                      {t}
                      <span style={{ marginLeft: "2px" }}>
                        {temparrFormNumbers[index]}
                      </span>
                    </div>
                  );
                } else {
                  return (
                    <div className={classes.Row} key={t}>
                      {t}
                      <CircleIcon style={{ color: "red" }} />
                    </div>
                  );
                }
              })}
              <div style={{ textAlign: "center" }}>
                {/* <button style={{minWidth:"40px", marginBottom:"10px"}} className='btn btn-success'> View All</button> */}
              </div>
            </Card>
          </Col>
        </Row>
        <Row style={{ display: "flex", justifyContent: "left" }}>
          <button
            style={{ margin: "10px" }}
            type="submit"
            className="btn btn-primary"
          >
            Save
          </button>
          <button
            style={{ margin: "10px" }}
            onClick={handleClickBack}
            className="btn btn-danger"
          >
            Back
          </button>
        </Row>
      </form>
    </Container>
  );
};
export default NewForm;
