import React from 'react';
import { NavLink } from 'reactstrap';
import classes from './NavigationItem.module.css';
const navigationItem =(props)=>{
    return(
    <li className={classes.NavigationItem}>
            <NavLink href={props.link} className={props.active ? classes.active:null}>
              {props.children}
            </NavLink>
        </li>

    );
}
export default navigationItem;