// project imports
// import config from 'config';

// action - state management
import * as actionTypes from '../actions/user.actions';

export const initialState = {
    isLoggedIn: false,
    userData: {},

};

// ==============================|| USER REDUCER ||============================== //

const userReducer = (state = initialState, action) => {
    let payload;
    switch (action.type) {
        case actionTypes.SET_USER_LOGGED_IN:
            payload = action.payload;
            return {
                ...state,
                isLoggedIn: true,
                userData: { ...state.userData, ...payload }
            };
        default:
            return state;
    }
};

export default userReducer;