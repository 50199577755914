import  { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

const useCheckSuperAdmin = () => {
    const { userData } = useSelector((state) => state.user)
    const [ifSuperAdmin, setIfSuperAdmin] = useState(false)
    console.log(userData);
    useEffect(() => {
        if (userData) {
            let emailDomain = userData.username?.split("@")[1]
            if (emailDomain === "iatseg.org" ) {
                // if (emailDomain === "iatseg.org") {
                setIfSuperAdmin(true)
            } else {
                setIfSuperAdmin(false)
            }
        }

        return () => {

        }
    }, [userData])


    return ifSuperAdmin

}

export default useCheckSuperAdmin