import React from 'react';
import classes  from './NavigationItems.module.css';
import NavigationItem from './NavigationItem/NavigationItem';
const navigationItems=()=>{
    const pathname = window.location.pathname;

    return(
    <ul className={classes.NavigationItems}>

       {pathname.search("mypublished") == -1 ? (<NavigationItem link="/" >Browse Forms</NavigationItem>) : null }
       {pathname.search("mypublished") == -1 ? (<NavigationItem  link="/Responses/:id"> Responses</NavigationItem>) : null }


{/* <NavigationItem  link="/">Profile</NavigationItem> */}

    </ul>

    )
};
export default navigationItems;