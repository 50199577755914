import React,{Fragment} from 'react';
import Logo from '../../Logo/Logo';
import NavigationItems from '../NavigationItems/NavigationItems';
import classes from './SideDrawer.module.css';
import BackDrop from '../../UI/BackDrop/BackDrop';

const sideDrawer =(props)=>{
    let attachedClasses=[classes.SideDrawer,classes.Close];
    if(props.open){
        attachedClasses=[classes.SideDrawer,classes.Open];
    }
return(
<Fragment>
    <BackDrop show={props.open} clicked={props.closed}/>
    <div  className={attachedClasses.join(' ')}>


        <Logo style={{marginBottom:"32px"}} height="11%"/>

        <nav>
            <NavigationItems/>
        </nav>

    </div>
</Fragment>
)
}
export default sideDrawer;